/* eslint-disable */
import Cookies from "js-cookie";

export default {
  init() {
    jQuery(document).on("click", "#accept-cookie", function (e) {
      e.preventDefault();

      Cookies.set("accepted-cookie", 1, {
        expires: 365,
      });

      jQuery(".cookie-banner").remove();
    });

    jQuery(document).on("click", ".mobile-hamburger", function () {
      jQuery(".mobile-nav").toggleClass("menu-open");
    });

    const $homeIframeToggle = $(".toggle-iframe"),
      $homeVideoToggle = $(".toggle-video");
    $homeIframeToggle.click(function (e) {
      e.preventDefault();
      $(this).parent().find("iframe")[0].src += "&autoplay=1";
      $(this).fadeOut();
    });

    $homeVideoToggle.click(function (e) {
      e.preventDefault();
      $(this).parent().find("video")[0].play();
      $(this).fadeOut();
    });

    function getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURI(dc.substring(begin + prefix.length, end));
    }

    var myCookie = getCookie("accepted-cookie");

    if (myCookie == null) {
    } else {
      $(".cookie-banner").css("display", "none");
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
