/* eslint-disable */
import Swiper, { Navigation } from "swiper";

export default {
  init() {
    var imageCardSwiper = new Swiper(".card-image-swiper", {
      modules: [Navigation],
      navigation: {
        nextEl: ".swiper-button-next-card",
        prevEl: ".swiper-button-prev-card",
      },
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        767: {
          slidesPerView: 1.8,
          spaceBetween: 20,
        },
        990: {
          slidesPerView: 3.05,
          spaceBetween: 20,
        },
      },
    });

    var boxItemsSwiper = new Swiper(".box-section-swiper", {
      modules: [Navigation],
      navigation: {
        nextEl: ".swiper-button-next-box",
        prevEl: ".swiper-button-prev-box",
      },
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        767: {
          slidesPerView: 2.05,
          spaceBetween: 20,
        },
        990: {
          slidesPerView: 3.2,
          spaceBetween: 20,
        },
        1100: {
          slidesPerView: 4.01,
          spaceBetween: 20,
        },
      },
    });
  },
  finalize() {},
};
