/* eslint-disable */

// import external dependencies
import "jquery";

// Import everything from autoload
import "./autoload/browserdetect.js";

// import local dependencies
import Router from "./util/Router";
import common from "./routes/common";
import home from "./routes/home";
import about from "./routes/about";
import blog from "./routes/blog";
import singlePost from "./routes/singlePost";
import science from "./routes/science";

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  about,
  //Blog Page
  blog,
  //Singlepost
  singlePost,
  //Science,
  science,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
