/* eslint-disable */
import "axios";

export default {
  init() {
    const axios = require("axios");
    const API_CLIENT = `https://fairhq.co/wp-json/wp/v2/posts`;
    const MOUNT_POINT = document.getElementById("posts-ajax");
    const NO_FOUND_MSG = document.getElementById("notfoundtext-blog");
    const LOADER = document.getElementById("loader-blog");
    const $categoryItem = $(".category-item");
    let BLOG_LIST = [];
    let homeUrl = window.location.origin + "/blog?cat=";
    let settings = {
      per_page: 100,
      page: 1,
    };
    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    var hasCat = getParameterByName("cat");
    if (hasCat != null) {
      console.info($("[data-id='" + hasCat + "']"));
      $("html,body").animate({
        scrollTop: $("#blogpagecat").offset().top,
      });
      setTimeout(() => {
        $('[data-id="' + hasCat + '"]').click();
      }, 500);
    }

    $categoryItem.click(function (e) {
      e.preventDefault();
      $(".category-item").removeClass("active");
      fetchPost(settings.page, $(this).data("id"));

      $(this).addClass("active");
    });

    function renderArticle(articles) {
      let blogHtml = "";
      articles.forEach((article) => {
        BLOG_LIST.push(article);
        if (article._embedded["wp:featuredmedia"]) {
          if (
            article._embedded["wp:featuredmedia"][0].code ==
            "rest_post_invalid_id"
          ) {
            //image but forbidden (no image html)
            blogHtml += `
            <div class="post">
             <div class="post-info-details">
                <a href="${
                  homeUrl + article._embedded["wp:term"][0][0].slug
                }" style="background-color: ${
              article._embedded["wp:term"][0][0].acf.color
            }">${article._embedded["wp:term"][0][0].name}</a>
                <p class="date">${article.formatted_date}</p>
              </div>
              <a class="title-permalink" href="${article.link}">${
              article.title.rendered
            }</a>
              <p>${article.excerpt.rendered}</p>
              <a class="post-permalink" href="${article.link}">
                Read more 
                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.67908L0 5.67908H8.61926L7.15006 7.12118C7.05462 7.21334 7.00084 7.33949 7.00084 7.47118C7.00084 7.60287 7.05462 7.72901 7.15006 7.82118L7.87045 8.53118C7.96571 8.62583 8.09538 8.67908 8.23065 8.67908C8.36591 8.67908 8.49558 8.62583 8.59084 8.53118L11.7768 5.40118C11.9177 5.25946 11.9977 5.06959 12 4.87118V4.49118C11.9998 4.29233 11.9195 4.1017 11.7768 3.96118L8.59084 0.831178C8.49558 0.736521 8.36591 0.683279 8.23065 0.683279C8.09538 0.683279 7.96571 0.736521 7.87045 0.831178L7.15006 1.53118C7.05402 1.62506 7 1.75286 7 1.88618C7 2.0195 7.05402 2.14729 7.15006 2.24118L8.61498 3.67908L0 3.67908Z" fill="#3333CC"/>
                </svg>
              </a>
            </div>
            `;
          } else {
            //image html
            let imgAlt = article._embedded["wp:featuredmedia"][0].alt_text
              ? article._embedded["wp:featuredmedia"][0].alt_text
              : article.title.rendered;
            blogHtml += `
                <div class="post">
                <a href="${article.link}" class="thumb">
                  <img src="${
                    article._embedded["wp:featuredmedia"][0].source_url
                  }" 
                    alt="${imgAlt}" />
                  </a>                 
                <div class="post-info-details">
                    <a href="${
                      homeUrl + article._embedded["wp:term"][0][0].slug
                    }" style="background-color: ${
              article._embedded["wp:term"][0][0].acf.color
            }">${article._embedded["wp:term"][0][0].name}</a>
                    <p class="date">${article.formatted_date}</p>
                  </div>
                  <a class="title-permalink" href="${article.link}">${
              article.title.rendered
            }</a>
                  <p>${article.excerpt.rendered}</p>
                  <a class="post-permalink" href="${article.link}">
                    Read more 
                    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.67908L0 5.67908H8.61926L7.15006 7.12118C7.05462 7.21334 7.00084 7.33949 7.00084 7.47118C7.00084 7.60287 7.05462 7.72901 7.15006 7.82118L7.87045 8.53118C7.96571 8.62583 8.09538 8.67908 8.23065 8.67908C8.36591 8.67908 8.49558 8.62583 8.59084 8.53118L11.7768 5.40118C11.9177 5.25946 11.9977 5.06959 12 4.87118V4.49118C11.9998 4.29233 11.9195 4.1017 11.7768 3.96118L8.59084 0.831178C8.49558 0.736521 8.36591 0.683279 8.23065 0.683279C8.09538 0.683279 7.96571 0.736521 7.87045 0.831178L7.15006 1.53118C7.05402 1.62506 7 1.75286 7 1.88618C7 2.0195 7.05402 2.14729 7.15006 2.24118L8.61498 3.67908L0 3.67908Z" fill="#3333CC"/>
                    </svg>
                  </a>
                </div>               
               `;
          }
        } else {
          //no image html
          blogHtml += `
            <div class="post">
             <div class="post-info-details">
                <a href="${
                  homeUrl + article._embedded["wp:term"][0][0].slug
                }" style="background-color: ${
            article._embedded["wp:term"][0][0].acf.color
          }">${article._embedded["wp:term"][0][0].name}</a>
                <p class="date">${article.formatted_date}</p>
              </div>
              <a class="title-permalink" href="${article.link}">${
            article.title.rendered
          }</a>
              <p>${article.excerpt.rendered}</p>
              <a class="post-permalink" href="${article.link}">
                Read more 
                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.67908L0 5.67908H8.61926L7.15006 7.12118C7.05462 7.21334 7.00084 7.33949 7.00084 7.47118C7.00084 7.60287 7.05462 7.72901 7.15006 7.82118L7.87045 8.53118C7.96571 8.62583 8.09538 8.67908 8.23065 8.67908C8.36591 8.67908 8.49558 8.62583 8.59084 8.53118L11.7768 5.40118C11.9177 5.25946 11.9977 5.06959 12 4.87118V4.49118C11.9998 4.29233 11.9195 4.1017 11.7768 3.96118L8.59084 0.831178C8.49558 0.736521 8.36591 0.683279 8.23065 0.683279C8.09538 0.683279 7.96571 0.736521 7.87045 0.831178L7.15006 1.53118C7.05402 1.62506 7 1.75286 7 1.88618C7 2.0195 7.05402 2.14729 7.15006 2.24118L8.61498 3.67908L0 3.67908Z" fill="#3333CC"/>
                </svg>
              </a>
            </div>
            `;
        }
      });
      MOUNT_POINT.innerHTML = blogHtml;
      LOADER.style.display = "none";
      MOUNT_POINT.style.opacity = 1;
      NO_FOUND_MSG.style.opacity = 1;
    }

    function fetchPost(page, cat) {
      MOUNT_POINT.style.opacity = 0;
      NO_FOUND_MSG.style.opacity = 0;
      LOADER.style.display = "block";
      let data = {};
      //Current cat from php var
      if (cat != null && cat !== "" && cat != undefined) {
        data["categories"] = cat;
      }
      data["per_page"] = settings.per_page;
      data["page"] = page;
      data["exclude"] = [EXCLUDEDFEATURE];
      //console.log(EXCLUDEDFEATURE);
      //Excluded from php array
      // data["exclude"] = excluded_blog_id;
      data["_embed"] = 1;
      // data["lang"] = LANG;

      axios
        .get(API_CLIENT, {
          params: data,
        })
        .then((response) => {
          var totalPages =
            response.headers && response.headers["x-wp-totalpages"];
          if (response.data.length == 0) {
            NO_FOUND_MSG.style.display = "block";
          } else {
            NO_FOUND_MSG.style.display = "none";
          }

          renderArticle(response.data);
        })
        .catch((e) => {
          LOADER.style.display = "none";
          console.log("error =>", e);
        });
    }
  },
};
