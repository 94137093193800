/* eslint-disable */

import Swiper, { Navigation, EffectFade } from "swiper";

export default {
  init() {
    var swiper = new Swiper(".customers-swiper", {
      modules: [Navigation, EffectFade],
      draggable: true,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
